.login {

}

.login__content {
  width: 300px;
  position: absolute;
  left: calc(50% - 170px);
  top: calc(50% - 130px);
  padding: 40px 20px;
  text-align: center;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
}

.login__title {
  font-size: 32px;
  margin-bottom: 50px;
}

.login__subtitle {
  font-size: 14px;
  margin-bottom: 10px;
}
