.meal {
  flex: 1;
}

.meal__content {
  padding: 20px;
  width: 600px;
}

.meal__change-request {
  padding: 20px;
  background-color: #69bf07;
  color: white;
  font-size: 16px;
}

.meal__change-request-top {
  display: flex;
  margin-bottom: 14px;
}

.meal__change-request-icon {
  height: 24px;
  width: 24px;
  margin-right: 10px;
  background-image: url(https://s3-ap-northeast-1.amazonaws.com/potluck-webapp/info.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.meal__change-request-title {
  flex: 1;
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
}

.meal__change-request-text {
  font-size: 14px;
}

.meal__change-request-images {
  display: flex;
}

.meal__change-request-image {
  height: 50px;
  width: 75px;
  border-radius: 2px;
  margin: 5px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.meal__name {
  font-size: 32px;
  margin-bottom: 10px;
}

.meal__form {
  font-size: 14px;
  margin-bottom: 20px;
}

.meal__item {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 12px 0 16px 0;
  font-size: 16px;
  position: relative;
}

.meal__item-key {
  margin-bottom: 10px;
  color: rgba(51, 51, 51, 1);
}

.meal__item-value {
  flex: 1;
}

.meal__item-loading {
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.meal__item-loading-icon {
  height: 60px;
  width: 60px;
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(https://s3-ap-northeast-1.amazonaws.com/potluck-webapp/loading.svg);
}

.meal__horizontal-field {
  display: flex;
}

.meal__horizontal-field > div:not(:last-child) {
  margin-right: 20px;
}

.meal__description {
  font-size: 14px;
  margin-bottom: 20px;
}

.meal__image {
  position: relative;
  height: 400px;
  width: 600px;
  border-radius: 4px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.meal__image:not(:first-child) {
  margin-top: 20px;
}

.meal__image-delete {
  position: absolute;
  top: -20px;
  right: -20px;
  height: 40px;
  width: 40px;
  background-color: white;
  border-radius: 20px;
  cursor: pointer;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, .5);
  transition: box-shadow .3s;
}

.meal__image-delete:hover {
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, .7);
}

.meal__image-delete-icon {
  height: 100%;
  width: 100%;
  background-image: url(https://s3-ap-northeast-1.amazonaws.com/potluck-admin/close.svg);
  background-size: 25px;
  background-position: center;
  background-repeat: no-repeat;
}

.meal__section {
}

.meal__section-title {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  margin: 30px 0 10px 0;
}

.meal__tags-input-wrapper {
  position: relative;
}

/* Hack for autocomplete plugin */
.meal__tags-input-wrapper > div > div {
  z-index: 10;
  max-height: 400px !important;
}

.meal__tags-input {
  flex: 1;
  box-sizing: border-box;
  width: 600px;
  height: 40px;
  font-size: 16px;
  line-height: 16px;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  outline: none;
  appearance: none;
  padding-left: 10px;
  margin-bottom: 10px;
}

.meal__tags-input-option {
  padding: 5px;
  background-color: white;
  border-top: 1px solid #e0e0e0;
}

.meal__tags-input-option--highlighted, .meal__tags-input-option:hover {
  background-color: rgba(245, 245, 245, 1);
  cursor: pointer;
}

.meal__tags {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
}

.meal__tag {
  position: relative;
  font-size: 12px;
  background-color: rgba(248, 249, 251, 1);
  padding: 5px 15px;
  margin: 5px;
  height: 26px;
  border: 1px solid rgba(0, 153, 206, 1);
  color: rgba(0, 153, 206, 1);
  border-radius: 19px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.meal__tag-delete {
  position: absolute;
  top: -10px;
  right: -10px;
  height: 20px;
  width: 20px;
  background-color: white;
  border-radius: 20px;
  cursor: pointer;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, .5);
  transition: box-shadow .3s;
}

.meal__tag-delete-icon {
  height: 100%;
  width: 100%;
  background-image: url(https://s3-ap-northeast-1.amazonaws.com/potluck-admin/close.svg);
  background-size: 15px;
  background-position: center;
  background-repeat: no-repeat;
}
