.new-meal {
  flex: 1;
}

.new-meal__content {
  padding: 20px;
  width: 600px;
}

.new-meal__name {
  font-size: 32px;
  margin-bottom: 10px;
}

.new-meal__form {
  font-size: 14px;
  margin-bottom: 20px;
}

.new-meal__item {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 12px 0 16px 0;
  font-size: 16px;
}

.new-meal__item-key {
  margin-bottom: 10px;
  color: rgba(51, 51, 51, 1);
}

.new-meal__item-value {
  flex: 1;
}

.new-meal__description {
  font-size: 14px;
  margin-bottom: 20px;
}

.new-meal__image {
  height: 400px;
  width: 600px;
  border-radius: 4px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.new-meal__image:not(:first-child) {
  margin-top: 20px;
}

.new-meal__section {
}

.new-meal__section-title {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  margin: 30px 0 10px 0;
}

.new-meal__tags-input-wrapper {
  position: relative;
}

/* Hack for autocomplete plugin */
.new-meal__tags-input-wrapper > div > div {
  z-index: 10;
  max-height: 400px !important;
}

.new-meal__tags-input {
  flex: 1;
  box-sizing: border-box;
  width: 600px;
  height: 40px;
  font-size: 16px;
  line-height: 16px;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  outline: none;
  appearance: none;
  padding-left: 10px;
  margin-bottom: 10px;
}

.new-meal__tags-input-option {
  padding: 5px;
  background-color: white;
  border-top: 1px solid #e0e0e0;
}

.new-meal__tags-input-option--highlighted, .new-meal__tags-input-option:hover {
  background-color: rgba(245, 245, 245, 1);
  cursor: pointer;
}

.new-meal__tags {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
}

.new-meal__tag {
  font-size: 12px;
  background-color: rgba(248, 249, 251, 1);
  padding: 5px 15px;
  margin: 5px;
  height: 26px;
  border: 1px solid rgba(0, 153, 206, 1);
  color: rgba(0, 153, 206, 1);
  border-radius: 19px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
