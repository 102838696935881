.big-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 340px;
  margin: 20px auto;
  background-color: #fd7b83;
  border-radius: 28px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  line-height: 56px;
  text-align: center;
  cursor: pointer;
  transition: opacity 0.3s, background-color 0.3s, transform .1s ;
}

.big-button:active {
  transform: scale(0.95);
}

.big-button:hover {
  opacity: 0.8;
}

.big-button--inactive:hover {
  opacity: 1;
}

.big-button--inactive:active {
  transform: none;
}

.big-button--loading:active {
  transform: none;
}

.big-button--full {
  width: 100%;
}

.big-button--clear {
  background-color: white;
  color: #fd7b83;
  border: 1px solid #fd7b83;
}

.big-button--inactive {
  cursor: default;
  background-color: lightgray;
}

.big-button__icon {
  height: 20px;
  width: 20px;
  margin-left: 10px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(https://s3-ap-northeast-1.amazonaws.com/potluck-webapp/arrow-right--white.svg);
}

.big-button__icon--loading {
  background-image: url(https://s3-ap-northeast-1.amazonaws.com/potluck-webapp/loading.svg);
}

.big-button__icon--check {
  background-image: url(https://s3-ap-northeast-1.amazonaws.com/potluck-webapp/check.svg);
}

@media screen and (max-width: 48em) {
  .big-button {
    max-width: 340px;
    width: 100%;
  }
}
