.form-field-textarea__item {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 12px 0 16px 0;
  font-size: 16px;
}

.form-field-textarea__item-key {
  margin-bottom: 10px;
  color: rgba(51, 51, 51, 1);
}

.form-field-textarea__item-value {
  flex: 1;
  position: relative;
}

.form-field-textarea__item-value textarea {
  box-sizing: border-box;
  width: 100%;
  height: 200px;
  font-size: 16px;
  line-height: 16px;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  outline: none;
  appearance: none;
  padding: 5px;
}

.form-field-textarea__loading {
  height: 20px;
  width: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(https://s3-ap-northeast-1.amazonaws.com/potluck-webapp/loading.svg);
}

.form-field-textarea__item-error {
  color: red;
}

@media screen and (max-width: 48em) {
}
