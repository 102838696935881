.tags {
  flex: 1;
  position: relative;
}

.tags__loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  background-image: url(https://s3-ap-northeast-1.amazonaws.com/potluck-webapp/loading.svg);
  background-size: 50px;
  background-repeat: no-repeat;
  background-position: center;
}

.tags__content {
  padding: 20px;
}

.tags__top-bar {
  display: flex;
  align-items: center;
}

.tags__title {
  font-size: 24px;
  margin: 20px 0;
  width: 100px;
}

.tags__input {
  flex: 1;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  font-size: 16px;
  line-height: 16px;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  outline: none;
  appearance: none;
  padding-left: 10px;
}

.tags__section {
  text-align: center;
  color: rgba(113, 113, 113, 1);
}

.tags__section-title {
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.tags__section-values {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
}

.tags__section-value {
  font-size: 12px;
  background-color: rgba(248, 249, 251, 1);
  padding: 5px 15px;
  margin: 5px;
  height: 26px;
  border: 1px solid rgba(0, 153, 206, 1);
  color: rgba(0, 153, 206, 1);
  border-radius: 19px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.tags__add-tag {
  display: flex;
  justify-content: center;
}

.tags__add-tag-button-wrapper {
  text-align: center;
  margin: 20px 0;
}

.tags__add-tag-button {
  background-color: rgba(248, 249, 251, 1);
  border: 1px solid rgba(0, 153, 206, 1);
  color: rgba(0, 153, 206, 1);
  border-radius: 30px;
  font-size: 16px;
  padding: 15px 25px;
  cursor: pointer;
}

.tags__add-tag-label {
  margin-top: 10px;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: bold;
}
