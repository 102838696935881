.meals {
  flex: 1;
  position: relative;
}

.meals__loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  background-image: url(https://s3-ap-northeast-1.amazonaws.com/potluck-webapp/loading.svg);
  background-size: 50px;
  background-repeat: no-repeat;
  background-position: center;
}

.meals__content {
  padding: 20px;
}

.meals__top-bar {
  display: flex;
  align-items: center;
}

.meals__title {
  font-size: 24px;
  margin: 20px 0;
  width: 100px;
}

.meals__input {
  flex: 1;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  font-size: 16px;
  line-height: 16px;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  outline: none;
  appearance: none;
  padding-left: 10px;
}

.meals__list {
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  align-content: flex-start;
  align-items: flex-start;
}

.meals__list a {
  text-decoration: none;
  color: black;
}
