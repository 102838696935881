.reset-password {
}

.reset-password__content {
  padding: 20px;
  width: 400px;
}

.reset-password__error {
  color: red;
}

@media screen and (max-width: 48em) {
  .reset-password {
  }
}
