.form-field-checkbox-ios {
  display: flex;
  margin: 10px 0;
}

.form-field-checkbox-ios-label {
  line-height: 36px;
  font-size: 14px;
  margin-left: 10px;
}

.form-field-checkbox-ios-input {
  position: relative;
  appearance: none; outline: none;
  width: 50px; height: 30px;
  background-color: #ffffff; border: 1px solid #D9DADC;
  border-radius: 50px; box-shadow: inset -20px 0 0 0 #ffffff;
  transition-duration: 200ms;
}

.form-field-checkbox-ios-input:after {
  content: ""; 
  position: absolute;
  top: 1px; left: 1px;
  width: 26px; height: 26px;
  background-color: transparent;
  border-radius: 50%; box-shadow: 2px 4px 6px rgba(0,0,0,0.2);
}

.form-field-checkbox-ios-input:checked {
  border-color: #fd7b83;
  box-shadow: inset 20px 0 0 0 #fd7b83;
}

.form-field-checkbox-ios-input:checked:after {
  left: 20px;
  box-shadow: -2px 4px 3px rgba(0,0,0,0.05);
}
