.shop__geo-suggest {
  position: relative;
}

/* Hack -- plugin */
.shop__geo-suggest .geosuggest__suggests-wrapper {
  z-index: 10;
  position: absolute;
  top: 36px;
  background-color: white;
  box-shadow: 0 10px 25px 0 rgba(50, 94, 128, .1);
  border: 1px solid #e4e4e4;
}

/* Hack -- plugin */
.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
}

.shop__geo-suggest ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.shop__geo-suggest li {
  height: 30px;
  padding: 0 10px;
  font-size: 12px;
  line-height: 30px;
  cursor: pointer;
}

.shop__geo-suggest li:not(:first-child) {
  border-top: 1px solid #e4e4e4;
}

.shop__geo-suggest li:hover {
  background-color: rgba(0, 0, 0, .1);
}

.shop__geo-suggest input {
  box-sizing: border-box;
  width: 100%;
  height: 56px;
  font-size: 16px;
  line-height: 16px;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  outline: none;
  appearance: none;
  padding-left: 10px;
}
