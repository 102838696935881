.nav {
  min-width: 260px;
  min-height: calc(100vh - 78px);
  background-color: white;
  padding: 5px 20px;
  box-sizing: border-box;
}

.nav__section {

}

.nav__section a {
  text-decoration: none;
}

.nav__section-title {
  color: black;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  padding: 5px 0;
}

.nav__item {
  color: #8da0aa;
  margin: 12px 0 12px 10px;
  display: flex;
  cursor: pointer;
  text-decoration: none;
}

.nav__item-icon {
  height: 20px;
  width: 20px;
  margin-right: 10px;
  background-size: contain;
  background-repeat: no-repeat;
}
